.add_agreement {
  display: flex;
  width: 100%;
  margin-right: 2rem;
  flex-direction: row-reverse;
  gap: 14px;
}

.button_agree {
  display: flex;
  align-items: center;
  min-height: 37px;
  justify-content: space-around;
  color: #673695;
  background: #fff;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
  border-radius: 8px;
  border: 1px solid #673695;
  padding: 0px 16px;
  cursor: pointer;
  height: 40px;
}

.agreement_box {
  width: 100%;
  display: flex;
}

.gnJzWt {
  margin: 40px;
}

label.label_edit_sow {
    margin: 12px 0;
    display: flex;
    justify-content: flex-start;
}

.btn_pos {
    position: absolute;
    right: 6px;
    bottom: 10px;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    height: 140px;
}

.div_card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_sample {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 180px;
    padding: 10px;
    border-radius: 8px;
    margin-top: -100px;
}

.allDetailsEditSowPage::-webkit-scrollbar {
    display: none;
}

.allDetailsEditSowPage {
    height: 74vh;
    overflow-x: scroll;
}

.imgs_css {
    border: 0.5px ridge black;
    border-radius: 10px;
}

.upload_css {
    border: 0.8px solid black;
    border-radius: 10px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload_css1 {
    border-radius: 10px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scroll_row {
    overflow: scroll;
    flex-wrap: nowrap !important;
}

.scroll_row::-webkit-scrollbar {
    display: none;
}

.delete_icon {
    position: absolute;
    right: 8px;
    top: 8px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: red;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete_icon1 {
    position: absolute;
    right: 36px;
    top: 18px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: red;
    cursor: pointer;
}

.total_delete1 {
    height: 280px;
    position: relative;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 25%);
    padding: 10px;
    border-radius: 10px;
}

.total_delete {
    position: relative;
}

.desc_css {
    margin-top: 10px;
    min-height: 60px;
    max-height: 60px;
    overflow: scroll;
    text-align: justify;
    margin: 5px;
}

.desc_css1 {
    margin-top: 10px;
    text-align: justify;
    margin: 5px;
}

.head_css_desc {
    font-weight: 600;
}

.customer_desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 70vh;
}

.customer_box {
    background: black;
    opacity: 0.6;
    margin: 12px;
    border-radius: 6px;
    min-height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    justify-content: center;
    z-index: 1000;
}

.carousel-item>img {
    width: 100%;
    height: 590px;
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    font-family: 'Poppins';
}

.carousel-dark .carousel-caption {
    color: #000;
    font-family: 'Poppins';
    font-size: 13px;
}

/* .sc-bcXHqe.jlCUqN.rdt_Table,
.sc-fLcnxK.RJkoq {
    min-height: 400px;
} */
.react-datepicker__header {
  background-color: #3f5289;
  color: #eceef3;
}

.example-custom-input {
  /* padding: 8px 43px 8px 14px; */
  width: 110px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #dce0e4;
  /* text-align: center; */
  padding-left: 8px;
}

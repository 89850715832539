.App {
  text-align: center;
}

html,
body {
  font-family: "Poppins", sans-serif;
}

table {
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar-track-piece {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 12px;
}

input.filter-text {
  float: right;
  width: 200px;
  height: 38px;
  border: lightgrey solid 1px;
  border-radius: 6px;
  margin: 0;
  padding-left: 10px;
  padding-left: 20px;
  padding-left: 1;
}

.custom-select__multi-value__remove {
  display: none;
}

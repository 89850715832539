.target-table {
  border: 1px solid black;
}

.target-table tr {
  border: 1px solid black;
}

.target-table td {
  border: 1px solid black;
}

.target-table th {
  border: 1px solid black;
}

.text-center {
  text-align: center;
}

.font-style {
  font-size: 11px;
}

#scroll-container::-webkit-scrollbar {
  height: 6px;
}

#scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

#scroll-container::-webkit-scrollbar-thumb:hover {
  background: #888;
}
